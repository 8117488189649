import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'

const Component = ({ to }) => {
  return (
    <LinkWrap className='back-arrow' to={to}>
      <Inview className='fade-in stagger-1000'>
        <span className='arrow arrow-bar is-left'></span>
      </Inview>
    </LinkWrap>
  )
}

export default Component
