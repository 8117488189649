import React from 'react'
import Image from 'components/Image'
import ImageWrap from 'components/ImageWrap'
import ModalVideo from 'components/ModalVideo'
import BackArrow from 'components/BackArrow'
import ButtonContact from 'components/ButtonContact'
import Inview from 'components/Inview'

class Component extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      videoSrcs: [],
    }
    this.setSelected = this.setSelected.bind(this)
  }

  componentDidMount() {
    const video = this.props.content.video
    if (video) {
      this.setState({
        videoSrcs: [`/videos/${video}`],
      })
    }
  }

  setSelected(id) {
    this.setState({
      selected: id,
    })
    const video = document.querySelector('.block-hero.has-video video')
    if (id !== null) {
      document.querySelector('html').classList.add('no-scroll')
      document.querySelector('.nav--holder').classList.add('hide')
      // setTimeout(() => {
      //   document.querySelector('.nav--holder').classList.add('up')
      // }, 300)
      video.src = this.state.videoSrcs[id]
      video.play()
    } else {
      document.querySelector('html').classList.remove('no-scroll')
      document.querySelector('.nav--holder').classList.remove('hide')
      video.pause()
    }
  }
  render() {
    const { content, mobileBackgroundClass, bannerShorter } = this.props
    const { image, gatsbyImage, video, textBlock, hasReturnButton } = content
    const { isDark, hasContactButton, buttonAddClass, text } = textBlock

    return (
      <section className={`block-hero relative ${video ? 'has-video' : ''}`}>
        <div
          className={`container flex items-center 
          ${isDark ? 'is-dark-mobile' : ''}
          ${bannerShorter ? 'banner-shorter' : ''}`}
        >
          <TextBlock
            text={text}
            hasContactButton={hasContactButton}
            buttonAddClass={buttonAddClass}
            hasReturnButton={hasReturnButton}
            isDark={isDark}
          />
          <div className='scroll--indicator'>向下划动</div>
          <BgDesktop
            video={video}
            image={image}
            gatsbyImage={gatsbyImage}
            setSelected={this.setSelected}
          />
        </div>
        <BgMobile
          mobileBackgroundClass={mobileBackgroundClass}
          video={video}
          image={image}
          gatsbyImage={gatsbyImage}
          setSelected={this.setSelected}
        />
        <ModalVideo
          selected={this.state.selected}
          setSelected={this.setSelected}
        />
      </section>
    )
  }
}

export default Component

const TextBlock = ({
  text,
  hasContactButton,
  buttonAddClass,
  hasReturnButton,
  isDark,
}) => {
  return (
    <Inview
      className={`text-block pt-16 pb-20 md:pt-16 lg:pt-14 lg:pb-14 lg:pr-20 relative bg--left fade-in-up ${
        isDark ? 'is-dark' : ''
      }`}
    >
      <div className='text'>
        {hasReturnButton && <ButtonReturn />}
        {text.pretitle && (
          <p
            className='pretitle fade-in-up stagger-200'
            dangerouslySetInnerHTML={{ __html: text.pretitle }}
          />
        )}
        {text.title && (
          <h1
            className='h1 smaller fade-in-up stagger-400'
            dangerouslySetInnerHTML={{ __html: text.title }}
          />
        )}
        {text.highlight && (
          <p className='highlight fade-in-up stagger-600'>{text.highlight}</p>
        )}
        {text.description && (
          <p
            className='description fade-in-up stagger-600'
            dangerouslySetInnerHTML={{ __html: text.description }}
          />
        )}
        {hasContactButton && (
          <div className='lg:flex items-center fade-in-up stagger-800'>
            <ButtonContact addClass={buttonAddClass} />
          </div>
        )}
      </div>
    </Inview>
  )
}

const ButtonReturn = () => {
  let basePath = ''
  if (typeof window !== 'undefined') {
    basePath = window.location.pathname.split('/')[1]
  }
  const link = `/${basePath}/`
  return <BackArrow to={link} />
}

const BgDesktop = ({ video, image, gatsbyImage, setSelected }) => {
  return (
    <Inview
      className={`bg-holder desktop hidden lg:block z-0 relative md:absolute right-0 top-0 fade-in stagger-600 
${video ? 'has-video' : ''}
`}
    >
      <div className='bg-image' onClick={video ? () => setSelected(0) : null}>
        {image && <Image filename={image} />}
        {gatsbyImage && <ImageWrap image={gatsbyImage} />}
      </div>
      {video && <VideoButton />}
    </Inview>
  )
}
const BgMobile = ({
  mobileBackgroundClass,
  video,
  image,
  gatsbyImage,
  setSelected,
}) => {
  return (
    <div
      className={`lg:hidden relative z-20 bg-mobile-wrap ${mobileBackgroundClass}`}
    >
      <Inview
        className={`bg-holder mobile fade-in relative
        ${video ? 'has-video' : ''}
      `}
      >
        <div className='bg-image' onClick={video ? () => setSelected(0) : null}>
          {image && <Image filename={image} />}
          {gatsbyImage && <ImageWrap image={gatsbyImage} />}
        </div>
        {video && <VideoButton />}
      </Inview>
    </div>
  )
}

const VideoButton = () => (
  <div className='btn--play color--primary'>
    <svg width='14' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.756 9.503L.669 18.379V.627l13.087 8.876z'
        fill='#fff'
      ></path>
    </svg>
  </div>
)
