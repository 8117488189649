import React from 'react'
import ImageWrap from 'components/ImageWrap'
import BodyWrap from 'components/BodyWrap'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  const { gatsbyImage, text, imagePos, bg } = content
  return (
    <section
      className={`
      block-text-image-post
      ${imagePos ? `has-image image-pos-${imagePos}` : 'no-image'}
      bg--${bg}
    `}
    >
      <div className='container'>
        <Inview className='text fade-in-up'>
          {text.title && <h3 className='h3 smaller'>{text.title}</h3>}
          {text.subtitle && <h6 className='h6'>{text.subtitle}</h6>}
          {text.body && (
            <div className='body'>
              <BodyWrap body={text.body} />
            </div>
          )}
        </Inview>
        {gatsbyImage && (
          <Inview className='image fade-in-up'>
            <ImageWrap image={gatsbyImage} />
          </Inview>
        )}
      </div>
    </section>
  )
}

export default Component
